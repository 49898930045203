.custom-link {
  color: #AEC8E1 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

.custom-link:hover,
p .custom-link:hover {
  color: #4b8ac1 !important;
  text-decoration: underline !important;
}

.custom-link:visited {
  color: #AEC8E1 !important;
  text-decoration: none !important;
}

a h4 {
  font-weight: bold;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  color:#413F49;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%); /* Start position off the screen on the left */
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    transform: translateX(0); /* End position, fully visible */
    opacity: 1; /* End with opacity 1 */
  }
}

header h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* src/components/About.css */
#about {
  padding: 4rem;
}

/* src/components/Projects.css */
#projects {
  padding: 2rem;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
}

.project {
  border: 1px solid #ddd;
  margin: 1rem;
  padding: 1rem;
  flex: 1 1 calc(33% - 2rem);
}

/* src/components/Contact.css */
#contact {
  padding: 2rem;
  background-color: #413F49;
  color: white;
  text-align: start;
}

.container {
  padding: 10%;
}
