
.background-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  display: flex;
  justify-content: left;
  animation: fadeIn 1s forwards ease-in;
  margin-right: 50px;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Align items with space between */
  padding: 20px;
}

.profile-info {
  flex: 1; /* Take remaining space */
  text-align: start;
}

.text-container {
  margin-bottom: 20px;
  text-align: start;
  word-wrap: break-word;
}

.profile-pic-container {
  flex-shrink: 0; /* Don't allow this container to shrink */
}

@media (max-width: 768px) {
  .profile-container {
    justify-content: center;
    text-align: center;
  }

  .profile-info {
    flex: 1;
    text-align: center;
  }

  .profile-pic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 0;
    display: flex;
    justify-content: center;
    animation: fadeIn 1s forwards ease-in;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
  }
}
